/* global config, moment, angular, zingchart, fetch */
'use strict';

angular.module('managerApp').controller('DashboardController', (
        $scope, $cookies, toast, Auth, dashboard, terminals, users, clients) => {

    const defaultErrorMessage = 'Une erreur est survenue lors du traitement de votre demande.';

    moment.locale('fr');
    moment.updateLocale('fr', {
        week: {
            dow: 1
        }
    });

//------------------------------------------------------------------------------

    const COLORS = {
        cim: {
            bgColor: '#e30517'
        },
        cad: {
            bgColor: '#448085'
        },
        dup: {
            bgColor: '#0075F6'
        },
        dc: {
            bgColor: '#8f53d0'
        },
        vel: {
            bgColor: '#00beca'
        },
        da: {
            bgColor: '#118400'
        },
        csa: {
            bgColor: '#56423e'
        },
        ciww: {
            bgColor: '#e30aaa'
        },
        civi: {
            bgColor: '#e30aaa'
        }
    };

    $scope.terminalPartners = [];
    $scope.terminals = [];

    let maxDate = new Date();
    let minDate = new Date(2020, 1, 1);

    $scope.hasDashboardPermission = Auth.hasPermissions('DASHBOARD');
    $scope.hasAgentsPermission = $scope.hasDashboardPermission && Auth.hasPermissions('USERS:GET');
    $scope.hasLaPostePermission = $scope.hasDashboardPermission && Auth.hasPermissions('SERVICE_CERTIFICAT_IMMATRICULATION:GET');

//------------------------------------------------------------------------------

    $scope.taxes = {
        maxDate: maxDate,
        dateFrom: null,
        dateTo: null,
        total: 0,
        count: 0,
        init: function () {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.total = this.count = 0;
            this.run();
        },
        run: function () {
            dashboard.getTaxes({
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            }).then((result) => {
                if (result && result.totalTaxes) {
                    this.total = result.totalTaxes;
                    this.count = result.count;
                }
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        }
    };

    $scope.refunds = {
        count: 0,
        total: 0,
        run: function () {
            dashboard.getRefunds().then((result) => {
                if (result && result.total) {
                    this.total = result.total;
                    this.count = result.count;
                    let expireDate = moment().add(15, 'm').toDate();
                    $cookies.put('refundsCount', this.count, {'expires': expireDate});
                }
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        }
    };

    $scope.nbProcedures = {
        maxDate: maxDate,
        dateFrom: new Date(2017, 1, 1),
        dateTo: new Date(),
        getNbByTerminal: () => {
            dashboard.getProceduresNbByTerminal({
                dateFrom: $scope.nbProcedures.dateFrom,
                dateTo: $scope.nbProcedures.dateTo
            }).then((result) => {
                if (result && result.file) {
                    downloadFile(result.file);
                }
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        },
        total: null
    };

    $scope.paidProceduresCountsPerPeriodForTerminalPartners = {

        minDate: minDate,
        maxDate: maxDate,
        dateFrom: null,
        dateTo: null,
        counts: null,
        selectedMonth: null,
        terminalPartnerId: null,
        terminalId: null,
        terminals: [],
        values: {
            cim: 0, cad: 0,
            dc: 0, dup: 0,
            vel: 0, da: 0,
            csa: 0, ciww: 0,
            civi: 0
        },
        chart: 'terminal_partners_chart_001',
        chartPerTerminalPartner: 'per_terminal_partners_chart_001',
        chartPerTerminal: 'per_terminals_chart_001',

        getTerminals: function (run = false) {
            this.terminalId = null;
            this.terminals = [];
            getTerminals(this.terminalPartnerId, true, (terminals, err = null) => {
                if (err !== null) {
                    console.error('getTerminals.error :');
                    console.log(err);
                    return;
                }
                this.terminals = terminals;
                setTimeout(() => {
                    $scope.$apply();
                    if (run === true) {
                        this.run();
                    }
                }, 850);
            });
        },
        selectTerminalPartner: function () {
            this.terminals = [];
            setTimeout(() => {
                this.run();
            });
            getTerminals(this.terminalPartnerId, true, (terminals) => {
                this.terminals = terminals;
                setTimeout(() => {
                    $scope.$apply();
                });
            });
        },
        resetValues: function () {
            this.values = {
                cim: 0, cad: 0,
                dc: 0, dup: 0,
                vel: 0, da: 0,
                csa: 0, ciww: 0,
                civi: 0
            };
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            if (run === true && this.data === null) {
                this.run();
            }//
        },
        run: function (updateTerminalsList = false) {
            if (updateTerminalsList === true) {
                this.getTerminals();
            }
            let options = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            };
            if (this.terminalId !== null && this.terminalId !== 'all') {
                options.terminalId = this.terminalId;
            } else if (this.terminalPartnerId !== null) {
                options.terminalPartnerId = this.terminalPartnerId;
            }
            dashboard.getPaidProceduresCounts(options).then((counts) => {
                this.resetValues();
                this.counts = counts;
                for (let i = 0; i < counts.length; i++) {
                    const row = counts[i];
                    switch (row.type) {
                        case 'certificat-immatriculation':
                            this.values.cim = row.count;
                            break;
                        case 'vente-en-ligne':
                            this.values.vel = row.count;
                            break;
                        case 'declaration-cession':
                            this.values.dc = row.count;
                            break;
                        case 'changement-adresse':
                            this.values.cad = row.count;
                            break;
                        case 'duplicata':
                            this.values.dup = row.count;
                            break;
                        case 'declaration-achat':
                            this.values.da = row.count;
                            break;
                        case 'certificat-situation-administrative':
                            this.values.csa = row.count;
                            break;
                        case 'certificat-immatriculation-ww':
                            this.values.ciww = row.count;
                            break;
                        case 'certificat-immatriculation-vi':
                            this.values.civi = row.count;
                            break;
                    }
                }
                this.draw();
            });

            options.perTerminal = 1;
            dashboard.getPaidProceduresCounts(options).then((data) => {
                this.drawPerTerminal(data);
            });

            dashboard.getPaidProceduresCounts({
                perTerminalPartner: 1,
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            }).then((data) => {
                this.drawPerTerminalPartner(data);
            });
        },

        draw: function () {
            let chartConfig = {
                type: 'bar',
                backgroundColor: '#fefefe',
                plotarea: {
                    margin: '30px 30px 50px 30px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: [
                        'Carte grise',
                        'Changement d\'adresse',
                        'Duplicata',
                        'Déclaration de cession',
                        'Vente en ligne',
                        "Déclaration d'achat",
                        'Cert. situation admin.'
                    ],
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 10,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: [
                            this.values.cim, this.values.cad, this.values.dup,
                            this.values.dc, this.values.vel, this.values.da,
                            this.values.csa
                        ],
                        rules: [
                            {
                                backgroundColor: COLORS.cim.bgColor,
                                rule: '%i==0'
                            },
                            {
                                backgroundColor: COLORS.cad.bgColor,
                                rule: '%i==1'
                            },
                            {
                                backgroundColor: COLORS.dup.bgColor,
                                rule: '%i==2'
                            },
                            {
                                backgroundColor: COLORS.dc.bgColor,
                                rule: '%i==3'
                            },
                            {
                                backgroundColor: COLORS.vel.bgColor,
                                rule: '%i==4'
                            },
                            {
                                backgroundColor: COLORS.da.bgColor,
                                rule: '%i==5'
                            },
                            {
                                backgroundColor: COLORS.csa.bgColor,
                                rule: '%i==6'
                            }
                        ]
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        },
        drawPerTerminalPartner: function (data) {

            let scaleXValues = [];
            let cimValues = [], cadValues = [], dupValues = [], dcValues = [], csaValues = [],
                    daValues = [], velValues = [];

            for (let i = 0; i < data.length; i++) {
                let item = data[i];
                scaleXValues.push(item.terminalPartner);
                cimValues.push(item['certificat-immatriculation']);
                velValues.push(item['vente-en-ligne']);
                dcValues.push(item['declaration-cession']);
                cadValues.push(item['changement-adresse']);
                daValues.push(item['declaration-achat']);
                daValues.push(item['duplicata']);
                csaValues.push(item['certificat-situation-administrative']);
            }

            let chartConfig = {
                graphset: [
                    {
                        globals: {
                            fontFamily: 'heebo'
                        },
                        type: 'vbar',
                        plotarea: {
                            margin: 'dynamic'
                        },
                        scaleX: {
                            values: scaleXValues,
                            item: {
                                offsetY: -5,
                                angle: 270
                            },
                            tick: {
                                size: 10
                            }
                        },
                        plot: {
                            "bars-space-left": 0.15,
                            "bars-space-right": 0.15
                        },
                        tooltip: {
                            visible: false
                        },
                        crosshairX: {
                            lineWidth: "100%",
                            alpha: 0.4
                        },
                        series: [
                            {
                                values: cimValues,
                                text: 'Carte grise',
                                backgroundColor: '#e30517'
                            },
                            {
                                values: cadValues,
                                text: 'Changement d\'adresse',
                                backgroundColor: '#448085'
                            },
                            {
                                values: dcValues,
                                text: 'Déclaration de cession',
                                backgroundColor: '#8f53d0'
                            },
                            {
                                values: csaValues,
                                text: 'Cert. de situation administrative',
                                backgroundColor: '#56423E'
                            },
                            {
                                values: velValues,
                                text: 'Vente en ligne',
                                backgroundColor: '#00beca'
                            },
                            {
                                values: daValues,
                                text: 'Déclaration d\'achat',
                                backgroundColor: '#118400'
                            }
                        ]
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chartPerTerminalPartner,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        },
        drawPerTerminal: function (data) {

            let scaleXValues = [];
            let cimValues = [], cadValues = [], dcValues = [];
            let csaValues = [], daValues = [], velValues = [], dupValues = [];
            for (let i = 0; i < data.length; i++) {
                let item = data[i];
                scaleXValues.push(item.terminal);
                cimValues.push(item['certificat-immatriculation']);
                velValues.push(item['vente-en-ligne']);
                dcValues.push(item['declaration-cession']);
                cadValues.push(item['changement-adresse']);
                daValues.push(item['declaration-achat']);
                csaValues.push(item['certificat-situation-administrative']);
                dupValues.push(item['duplicata']);
            }

            let chartConfig = {
                graphset: [
                    {
                        globals: {
                            fontFamily: 'heebo'
                        },
                        type: 'vbar',
                        plotarea: {
                            margin: 'dynamic'
                        },
                        scaleX: {
                            values: scaleXValues,
                            item: {
                                offsetY: -5,
                                angle: 270
                            },
                            tick: {
                                size: 10
                            }
                        },
                        plot: {
                            "bars-space-left": 0.15,
                            "bars-space-right": 0.15
                        },
                        tooltip: {
                            visible: false
                        },
                        crosshairX: {
                            lineWidth: "100%",
                            alpha: 0.4
                        },
                        series: [
                            {
                                values: cimValues,
                                text: 'Carte grise',
                                backgroundColor: COLORS.cim.bgColor
                            },
                            {
                                values: cadValues,
                                text: 'Changement d\'adresse',
                                backgroundColor: COLORS.cad.bgColor
                            },
                            {
                                values: dupValues,
                                text: 'Duplicata',
                                backgroundColor: COLORS.dup.bgColor
                            },
                            {
                                values: dcValues,
                                text: 'Déclaration de cession',
                                backgroundColor: COLORS.dc.bgColor
                            },
                            {
                                values: csaValues,
                                text: 'Cert. de situation administrative',
                                backgroundColor: COLORS.csa.bgColor
                            },
                            {
                                values: velValues,
                                text: 'Vente en ligne',
                                backgroundColor: COLORS.vel.bgColor
                            },
                            {
                                values: daValues,
                                text: 'Déclaration d\'achat',
                                backgroundColor: COLORS.da.bgColor
                            }
                        ]
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chartPerTerminal,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };

    $scope.paidProceduresCountsPerPeriod = {
        minDate: minDate,
        maxDate: maxDate,
        dateFrom: null,
        dateTo: null,
        counts: null,
        selectedMonth: null,
        values: {
            cim: [0, 0],
            cad: [0, 0],
            dc: [0, 0],
            vel: [0, 0],
            da: [0, 0]
        },
        chart: 'chart_003',
        resetValues: function () {
            this.values = {
                cim: [0, 0],
                cad: [0, 0],
                dc: [0, 0],
                vel: [0, 0],
                da: [0, 0]
            };
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        init: function () {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.run();
        },
        run: function () {
            dashboard.getPaidProceduresCounts({
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            }).then((counts) => {
                this.resetValues();
                this.counts = counts;
                for (let i = 0; i < counts.length; i++) {
                    const row = counts[i];
                    switch (row.type) {
                        case 'certificat-immatriculation':
                            this.values.cim[row.origin === 'borne' ? 0 : 1] = row.count;
                            break;
                        case 'vente-en-ligne':
                            this.values.vel[row.origin === 'borne' ? 0 : 1] = row.count;
                            break;
                        case 'declaration-cession':
                            this.values.dc[row.origin === 'borne' ? 0 : 1] = row.count;
                            break;
                        case 'changement-adresse':
                            this.values.cad[row.origin === 'borne' ? 0 : 1] = row.count;
                            break;
                        case 'declaration-achat':
                            this.values.da[row.origin === 'borne' ? 0 : 1] = row.count;
                            break;
                    }
                }
                this.draw();
            });
        },

        draw: function () {

            let terminalValues = [], mobileAppValues = [];
            for (let i in this.values) {
                let v = this.values[i];
                terminalValues.push(v[0]);
                mobileAppValues.push(v[1]);
            }

            let chartConfig = {
                type: 'bar',
                backgroundColor: '#fff',
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                legend: {
                    backgroundColor: '#fff',
                    padding: '5px 10px 5px 10px',
                    item: {
                        fontColor: '#000'
                    },
                    margin: '10px auto 0 auto'
                },
                scaleX: {
                    text: 'Type',
                    values: ['Carte grise', 'Changement d\'adresse', 'Déclaration de cession', 'Vente en ligne', 'Déclaration d\'achat'],
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 100,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: terminalValues,
                        backgroundColor: '#0a0',
                        text: 'Borne'
                    },
                    {
                        values: mobileAppValues,
                        backgroundColor: '#c00',
                        text: 'Appli mobile'
                    }
                ]
            };

            chartConfig.plot.animation = {
                method: 'LINEAR',
                delay: 0,
                effect: 'ANIMATION_EXPAND_VERTICAL',
                sequence: 'ANIMATION_BY_PLOT_AND_NODE',
                speed: 10
            };

            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };

    $scope.paidProceduresCounts = {
        dateFrom: null,
        dateTo: null,
        data: null,
        values: {
            cim: 0,
            cad: 0,
            dup: 0,
            dc: 0,
            vel: 0,
            da: 0,
            ciww: 0,
            civi: 0
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            if (run === true && this.data === null) {
                this.run();
            }//
        },
        run: function () {
            if ($scope.hasDashboardPermission) {
                dashboard.getPaidProceduresCounts().then((counts) => {
                    this.data = counts;
                    for (let i = 0; i < this.data.length; i++) {
                        const row = this.data[i];
                        switch (row.type) {
                            case 'certificat-immatriculation':
                                this.values.cim = row.count;
                                break;
                            case 'certificat-immatriculation-ww':
                                this.values.ciww = row.count;
                                break;
                            case 'certificat-immatriculation-vi':
                                this.values.civi = row.count;
                                break;
                            case 'vente-en-ligne':
                                this.values.vel = row.count;
                                break;
                            case 'declaration-cession':
                                this.values.dc = row.count;
                                break;
                            case 'changement-adresse':
                                this.values.cad = row.count;
                                break;
                            case 'declaration-achat':
                                this.values.da = row.count;
                                break;
                            case 'duplicata':
                                this.values.dup = row.count;
                                break;
                        }
                    }
                });
            }
        }
    };

    $scope.proceduresCountByOrigin = {
        maxDate: maxDate,
        dateFrom: '2020-01-01',
        dateTo: null,
        data: [],
        chart: 'chart_002',
        values: [
            {
                name: 'App mobile',
                count: 0,
                color: '#f00'
            },
            {
                name: 'Borne',
                count: 0,
                color: '#f90'
            },
            {
                name: 'Tablette Pro',
                count: 0,
                color: '#03f'
            },
            {
                name: 'Site web',
                count: 0,
                color: '#090'
            },
            {
                name: 'Back-office',
                count: 0,
                color: '#000'
            }
        ],
        total: 0,
        run: () => {
            dashboard.getProceduresCountByOrigin({
                date_from: $scope.proceduresCountByOrigin.dateFrom,
                date_to: $scope.proceduresCountByOrigin.dateTo
            }).then((data) => {
                for (let i = 0; i < data.length; i++) {
                    const row = data[i];
                    switch (row.origin) {
                        case 'app-mobile':
                            $scope.proceduresCountByOrigin.values[0].count = row.count;
                            break;
                        case 'borne':
                            $scope.proceduresCountByOrigin.values[1].count = row.count;
                            break;
                        case 'app-tablette-pro':
                            $scope.proceduresCountByOrigin.values[2].count = row.count;
                            break;
                    }
                    $scope.proceduresCountByOrigin.total += parseInt(row.count);
                    $scope.proceduresCountByOrigin.draw();
                }
            }).catch((err) => {
                console.error('error :');
                console.log(err);
            });
        },
        draw: () => {
            let data = {
                "type": "ring",
                plot: {
                    slice: '50%'
                },
                "legend": {
                    border: "1px solid #ccc",
                    borderRadius: '5px',
                    layout: "horizontal",
                    align: "center",
                    offsetY: "0px",
                    marginBottom: "10px",
                    position: "relative",
                    bottom: "10px"
                },
                series: []
            };
            for (let i = 0; i < $scope.proceduresCountByOrigin.values.length; i++) {
                let item = $scope.proceduresCountByOrigin.values[i];
                data.series.push({
                    text: item.name,
                    values: [item.count],
                    backgroundColor: item.color
                });
            }
            zingchart.render({
                id: $scope.proceduresCountByOrigin.chart,
                data: data,
                height: '500px',
                width: '100%'
            });
        }
    };

    $scope.proceduresTotalsAndCountsForAPeriod = {
        maxDate: maxDate,
        dateFrom: null,
        dateTo: null,
        data: [],
        chart: 'chart_01',
        init: function (fromPage = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.run(fromPage);
        },
        run: function (fromPage = false) {
            if (fromPage && this.data.length > 0) {
                return;
            }
            dashboard.getProceduresTotalsAndCountsForAPeriod({
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            }).then((data) => {
                this.data = data;
                this.draw();
            }).catch((err) => {
                console.error('error :');
                console.log(err);
            });
        },
        draw: function () {
            let chartConfig = {
                globals: {
                    color: '#666'
                },
                backgroundColor: '#fff',
                graphset: [
                    {
                        type: 'line',
                        plotarea: {
                            margin: '30px 30px 80px 100px'
                        },
                        legend: {
                            margin: 'auto auto 15 auto',
                            backgroundColor: 'none',
                            borderWidth: '0px',
                            item: {
                                margin: '0px',
                                padding: '0px',
                                fontSize: '11px'
                            },
                            layout: 'x2',
                            marker: {
                                type: 'match',
                                lineWidth: '2px',
                                showLine: 'true',
                                size: 10
                            },
                            shadow: false
                        },
                        scaleX: {
                            values: getDates(this.data, 'cim'),
                            guide: {
                                visible: false
                            },
                            item: {
                                paddingTop: '5px',
                                fontColor: '#8391a5',
                                fontFamily: 'Arial',
                                fontSize: '10px'
                            },
                            lineColor: '#d2dae2',
                            lineWidth: '2px',
                            tick: {
                                lineColor: '#d2dae2',
                                lineWidth: '1px'
                            }
                        },
                        scaleY: {
                            values: '0:80:1',
                            item: {
                                fontColor: '#09F'
                            },
                            lineColor: '#09F',
                            placement: 'default',
                            tick: {
                                lineColor: '#09F'
                            }
                        },
                        scaleY2: {
                            values: '0:2000:100',
                            item: {
                                fontColor: '#f00'
                            },
                            lineColor: '#f00',
                            placement: 'default',
                            tick: {
                                lineColor: '#f00'
                            }
                        },
                        crosshairX: {
                            lineColor: '#ccc',
                            plotLabel: {
                                backgroundColor: '#fff',
                                borderColor: '#ccc',
                                borderRadius: '5px',
                                borderWidth: '1px',
                                fontColor: '#414042'
                            },
                            scaleLabel: {
                                backgroundColor: '#ccc',
                                fontColor: '#000'
                            }
                        },
                        tooltip: {
                            visible: false
                        },
                        series: [
                            {
                                text: 'Chiffre d\'affaire',
                                values: getTotals(this.data, 'cim'),
                                lineColor: '#F00',
                                marker: {
                                    backgroundColor: '#fff',
                                    borderColor: '#F00',
                                    borderWidth: '2px'
                                },
                                scales: 'scale-x, scale-y-2'
                            },
                            {
                                text: 'Nombre de démarches',
                                values: getCounts(this.data, 'cim'),
                                lineColor: '#09F',
                                marker: {
                                    backgroundColor: '#fff',
                                    borderColor: '#09F',
                                    borderWidth: '2px'
                                },
                                scales: 'scale-x, scale-y'
                            }
                        ]
                    }
                ]
            };
            zingchart.render({
                id: this.chart,
                data: chartConfig,
                height: '100%',
                width: '100%'
            });
        }
    };

    $scope.laPosteWeeklyData = {
        minDate: minDate,
        maxDate: null,
        dateFrom: null,
        terminalPartnerId: null,
        onlyMondaysPredicate: function (date) {
            let day = date.getDay();
            return day === 1;
        },
        init: function () {
            this.terminalPartnerId = '58b7ccb0-7f22-4b27-aad3-5bbd015de59c';
            this.maxDate = moment().startOf('week').toDate();
        },
        run: function () {
            dashboard.getWeeklyStats({
                terminalPartnerId: this.terminalPartnerId,
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD')
            }).then((result) => {
                if (result && result.file) {
                    downloadFile(result.file);
                }
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        }
    };

    function getShortMonth(month) {
        month--;
        return [
            'Jan', 'Fév', 'Mar', 'Avr', 'Mai', 'Juin',
            'Juil', 'Aoû', 'Sep', 'Oct', 'Nov', 'Déc'
        ][month];
    }

    $scope.getEvolutionsStats = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        terminalId: null,
        terminalPartnerId: null,
        chart: 'chart_05',
        chart_global: 'chart_global_05',
        perDay: false,
        perWeek: false,
        perMonth: false,
        perYear: false,
        data: null,
        mode: null,
        terminals: null,
        origin: null,
        selectMode: function () {
            this.perDay = this.perWeek = this.perMonth = this.perYear = false;
            switch (this.mode) {
                case 'day':
                    this.perDay = true;
                    break;
                case 'week':
                    this.perWeek = true;
                    break;
                case 'month':
                    this.perMonth = true;
                    break;
                case 'year':
                    this.perYear = true;
                    break;
                default:
                    this.perDay = true;
            }
            this.run();
        },
        selectOrigin: function () {
            this.run();
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectTerminalPartner: function () {
            this.terminals = [];
            this.terminalId = null;
            setTimeout(() => {
                this.run();
            });
            getTerminals(this.terminalPartnerId, true, (terminals) => {
                this.terminals = terminals;
            });
        },
        selectTerminal: function () {
            setTimeout(() => {
                this.run();
            });
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.perDay = true;
            if (run === true && this.data === null) {
                this.run();
            }//
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            };
            if (this.terminalId !== null) {
                params.terminalId = this.terminalId;
            } else if (this.terminalPartnerId !== null) {
                params.terminalPartnerId = this.terminalPartnerId;
            }
            if (this.perDay === true) {
                params.perDay = 1;
            } else if (this.perWeek === true) {
                params.perWeek = 1;
            } else if (this.perMonth === true) {
                params.perMonth = 1;
            } else if (this.perYear === true) {
                params.perYear = 1;
            }
            if (this.origin !== null) {
                params.origin = this.origin;
            }
            dashboard.getEvolutionStats(params).then((data) => {
                this.perDay = data.perDay === true;
                this.perWeek = data.perWeek === true;
                this.perMonth = data.perMonth === true;
                this.perYear = data.perYear === true;
                this.mode = data.mode;
                this.data = data.values;
                this.draw();
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        },
        draw: function (global = false) {
            let labels = [], values = [];
            if (this.data === null) {
                return;
            }
            for (let i = 0; i < this.data.length; i++) {
                let row = this.data[i], label = '';
                values.push(row.count);
                if (this.perDay) {
                    label = row.day + ' ' + getShortMonth(row.month);
                } else if (this.perWeek) {
                    label = row.week;
                } else if (this.perMonth) {
                    label = getShortMonth(row.month) + ' ' + (row.year + '').substr(-2, 2);
                } else if (this.perYear) {
                    label = row.year + '';
                }
                labels.push(label);
            }

            let chartConfig = {
                type: 'bar',
                backgroundColor: '#fefefe',
                plotarea: {
                    margin: '30px 30px 50px 30px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: labels,
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 10,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: values
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };

    $scope.cimEvolutionsStats = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        terminalId: null,
        terminalPartnerId: null,
        chart: 'chart_cim_evo',
        perDay: false,
        perWeek: false,
        perMonth: false,
        perYear: false,
        data: null,
        mode: 'day',
        terminals: null,
        origin: 'all',
        procedureType: 'certificat-immatriculation',
        selectMode: function () {
            this.perDay = this.perWeek = this.perMonth = this.perYear = false;
            switch (this.mode) {
                case 'day':
                    this.perDay = true;
                    break;
                case 'week':
                    this.perWeek = true;
                    break;
                case 'month':
                    this.perMonth = true;
                    break;
                case 'year':
                    this.perYear = true;
                    break;
                default:
                    this.perDay = true;
            }
            this.run();
        },
        selectOrigin: function () {
            this.run();
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectTerminalPartner: function () {
            this.terminals = [];
            setTimeout(() => {
                this.run();
            });
            getTerminals(this.terminalPartnerId, true, (terminals) => {
                this.terminals = terminals;
            });
        },
        selectTerminal: function () {
            setTimeout(() => {
                this.run();
            });
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.perDay = true;
            if (run && this.data === null) {
                this.run();
            }//
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                procedureType: this.procedureType
            };
            if (this.terminalId !== null) {
                params.terminalId = this.terminalId;
            } else if (this.terminalPartnerId !== null) {
                params.terminalPartnerId = this.terminalPartnerId;
            }
            if (this.perDay === true) {
                params.perDay = 1;
            } else if (this.perWeek === true) {
                params.perWeek = 1;
            } else if (this.perMonth === true) {
                params.perMonth = 1;
            } else if (this.perYear === true) {
                params.perYear = 1;
            }
            if (this.origin !== null) {
                params.origin = this.origin;
            }
            dashboard.getEvolutionStats(params).then((data) => {
                this.perDay = data.perDay === true;
                this.perWeek = data.perWeek === true;
                this.perMonth = data.perMonth === true;
                this.perYear = data.perYear === true;
                this.mode = data.mode;
                this.data = data.values;
                this.draw();
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        },
        draw: function () {
            let labels = [], values = [];
            if (this.data === null) {
                return;
            }
            for (let i = 0; i < this.data.length; i++) {
                let row = this.data[i], label = '';
                values.push(row.count);
                if (this.perDay) {
                    label = row.day + ' ' + getShortMonth(row.month);
                } else if (this.perWeek) {
                    label = row.week;
                } else if (this.perMonth) {
                    label = getShortMonth(row.month) + ' ' + (row.year + '').substr(-2, 2);
                } else if (this.perYear) {
                    label = row.year + '';
                }
                labels.push(label);
            }
            let chartConfig = {
                type: 'bar',
                backgroundColor: '#fefefe',
                plotarea: {
                    margin: '30px 30px 50px 30px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: labels,
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 5,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: values,
                        backgroundColor: COLORS.cim.bgColor
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };
    $scope.ciwwEvolutionsStats = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        terminalId: null,
        terminalPartnerId: null,
        chart: 'chart_ciww_evo',
        perDay: false,
        perWeek: false,
        perMonth: false,
        perYear: false,
        data: null,
        mode: 'day',
        terminals: null,
        origin: 'all',
        procedureType: 'certificat-immatriculation-ww',
        selectMode: function () {
            this.perDay = this.perWeek = this.perMonth = this.perYear = false;
            switch (this.mode) {
                case 'day':
                    this.perDay = true;
                    break;
                case 'week':
                    this.perWeek = true;
                    break;
                case 'month':
                    this.perMonth = true;
                    break;
                case 'year':
                    this.perYear = true;
                    break;
                default:
                    this.perDay = true;
            }
            this.run();
        },
        selectOrigin: function () {
            this.run();
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectTerminalPartner: function () {
            this.terminals = [];
            setTimeout(() => {
                this.run();
            });
            getTerminals(this.terminalPartnerId, true, (terminals) => {
                this.terminals = terminals;
            });
        },
        selectTerminal: function () {
            setTimeout(() => {
                this.run();
            });
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.perDay = true;
            if (run && this.data === null) {
                this.run();
            }//
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                procedureType: this.procedureType
            };
            if (this.terminalId !== null) {
                params.terminalId = this.terminalId;
            } else if (this.terminalPartnerId !== null) {
                params.terminalPartnerId = this.terminalPartnerId;
            }
            if (this.perDay === true) {
                params.perDay = 1;
            } else if (this.perWeek === true) {
                params.perWeek = 1;
            } else if (this.perMonth === true) {
                params.perMonth = 1;
            } else if (this.perYear === true) {
                params.perYear = 1;
            }
            if (this.origin !== null) {
                params.origin = this.origin;
            }
            dashboard.getEvolutionStats(params).then((data) => {
                this.perDay = data.perDay === true;
                this.perWeek = data.perWeek === true;
                this.perMonth = data.perMonth === true;
                this.perYear = data.perYear === true;
                this.mode = data.mode;
                this.data = data.values;
                this.draw();
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        },
        draw: function () {
            let labels = [], values = [];
            if (this.data === null) {
                return;
            }
            for (let i = 0; i < this.data.length; i++) {
                let row = this.data[i], label = '';
                values.push(row.count);
                if (this.perDay) {
                    label = row.day + ' ' + getShortMonth(row.month);
                } else if (this.perWeek) {
                    label = row.week;
                } else if (this.perMonth) {
                    label = getShortMonth(row.month) + ' ' + (row.year + '').substr(-2, 2);
                } else if (this.perYear) {
                    label = row.year + '';
                }
                labels.push(label);
            }
            let chartConfig = {
                type: 'bar',
                backgroundColor: '#fefefe',
                plotarea: {
                    margin: '30px 30px 50px 30px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: labels,
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 5,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: values,
                        backgroundColor: COLORS.ciww.bgColor
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };
    $scope.civiEvolutionsStats = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        terminalId: null,
        terminalPartnerId: null,
        chart: 'chart_civi_evo',
        perDay: false,
        perWeek: false,
        perMonth: false,
        perYear: false,
        data: null,
        mode: 'day',
        terminals: null,
        origin: 'all',
        procedureType: 'certificat-immatriculation-vi',
        selectMode: function () {
            this.perDay = this.perWeek = this.perMonth = this.perYear = false;
            switch (this.mode) {
                case 'day':
                    this.perDay = true;
                    break;
                case 'week':
                    this.perWeek = true;
                    break;
                case 'month':
                    this.perMonth = true;
                    break;
                case 'year':
                    this.perYear = true;
                    break;
                default:
                    this.perDay = true;
            }
            this.run();
        },
        selectOrigin: function () {
            this.run();
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectTerminalPartner: function () {
            this.terminals = [];
            setTimeout(() => {
                this.run();
            });
            getTerminals(this.terminalPartnerId, true, (terminals) => {
                this.terminals = terminals;
            });
        },
        selectTerminal: function () {
            setTimeout(() => {
                this.run();
            });
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.perDay = true;
            if (run && this.data === null) {
                this.run();
            }//
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                procedureType: this.procedureType
            };
            if (this.terminalId !== null) {
                params.terminalId = this.terminalId;
            } else if (this.terminalPartnerId !== null) {
                params.terminalPartnerId = this.terminalPartnerId;
            }
            if (this.perDay === true) {
                params.perDay = 1;
            } else if (this.perWeek === true) {
                params.perWeek = 1;
            } else if (this.perMonth === true) {
                params.perMonth = 1;
            } else if (this.perYear === true) {
                params.perYear = 1;
            }
            if (this.origin !== null) {
                params.origin = this.origin;
            }
            dashboard.getEvolutionStats(params).then((data) => {
                this.perDay = data.perDay === true;
                this.perWeek = data.perWeek === true;
                this.perMonth = data.perMonth === true;
                this.perYear = data.perYear === true;
                this.mode = data.mode;
                this.data = data.values;
                this.draw();
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        },
        draw: function () {
            let labels = [], values = [];
            if (this.data === null) {
                return;
            }
            for (let i = 0; i < this.data.length; i++) {
                let row = this.data[i], label = '';
                values.push(row.count);
                if (this.perDay) {
                    label = row.day + ' ' + getShortMonth(row.month);
                } else if (this.perWeek) {
                    label = row.week;
                } else if (this.perMonth) {
                    label = getShortMonth(row.month) + ' ' + (row.year + '').substr(-2, 2);
                } else if (this.perYear) {
                    label = row.year + '';
                }
                labels.push(label);
            }
            let chartConfig = {
                type: 'bar',
                backgroundColor: '#fefefe',
                plotarea: {
                    margin: '30px 30px 50px 30px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: labels,
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 5,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: values,
                        backgroundColor: COLORS.civi.bgColor
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };

    $scope.cadEvolutionsStats = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        terminalId: null,
        terminalPartnerId: null,
        chart: 'chart_cad_evo',
        perDay: false,
        perWeek: false,
        perMonth: false,
        perYear: false,
        data: null,
        mode: 'day',
        terminals: null,
        origin: 'all',
        procedureType: 'changement-adresse',
        selectMode: function () {
            this.perDay = this.perWeek = this.perMonth = this.perYear = false;
            switch (this.mode) {
                case 'day':
                    this.perDay = true;
                    break;
                case 'week':
                    this.perWeek = true;
                    break;
                case 'month':
                    this.perMonth = true;
                    break;
                case 'year':
                    this.perYear = true;
                    break;
                default:
                    this.perDay = true;
            }
            this.run();
        },
        selectOrigin: function () {
            this.run();
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectTerminalPartner: function () {
            this.terminals = [];
            setTimeout(() => {
                this.run();
            });
            getTerminals(this.terminalPartnerId, true, (terminals) => {
                this.terminals = terminals;
            });
        },
        selectTerminal: function () {
            setTimeout(() => {
                this.run();
            });
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.perDay = true;
            if (run && this.data === null) {
                this.run();
            }//
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                procedureType: this.procedureType
            };
            if (this.terminalId !== null) {
                params.terminalId = this.terminalId;
            } else if (this.terminalPartnerId !== null) {
                params.terminalPartnerId = this.terminalPartnerId;
            }
            if (this.perDay === true) {
                params.perDay = 1;
            } else if (this.perWeek === true) {
                params.perWeek = 1;
            } else if (this.perMonth === true) {
                params.perMonth = 1;
            } else if (this.perYear === true) {
                params.perYear = 1;
            }
            if (this.origin !== null) {
                params.origin = this.origin;
            }
            dashboard.getEvolutionStats(params).then((data) => {
                this.perDay = data.perDay === true;
                this.perWeek = data.perWeek === true;
                this.perMonth = data.perMonth === true;
                this.perYear = data.perYear === true;
                this.mode = data.mode;
                this.data = data.values;
                this.draw();
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        },
        draw: function () {
            let labels = [], values = [];
            if (this.data === null) {
                return;
            }
            for (let i = 0; i < this.data.length; i++) {
                let row = this.data[i], label = '';
                values.push(row.count);
                if (this.perDay) {
                    label = row.day + ' ' + getShortMonth(row.month);
                } else if (this.perWeek) {
                    label = row.week;
                } else if (this.perMonth) {
                    label = getShortMonth(row.month) + ' ' + (row.year + '').substr(-2, 2);
                } else if (this.perYear) {
                    label = row.year + '';
                }
                labels.push(label);
            }
            let chartConfig = {
                type: 'bar',
                backgroundColor: '#fefefe',
                plotarea: {
                    margin: '30px 30px 50px 30px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: labels,
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 5,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: values,
                        backgroundColor: COLORS.cad.bgColor
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };

    $scope.dcEvolutionsStats = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        terminalId: null,
        terminalPartnerId: null,
        chart: 'chart_dc_evo',
        perDay: false,
        perWeek: false,
        perMonth: false,
        perYear: false,
        data: null,
        mode: 'day',
        terminals: null,
        origin: 'all',
        procedureType: 'declaration-cession',
        selectMode: function () {
            this.perDay = this.perWeek = this.perMonth = this.perYear = false;
            switch (this.mode) {
                case 'day':
                    this.perDay = true;
                    break;
                case 'week':
                    this.perWeek = true;
                    break;
                case 'month':
                    this.perMonth = true;
                    break;
                case 'year':
                    this.perYear = true;
                    break;
                default:
                    this.perDay = true;
            }
            this.run();
        },
        selectOrigin: function () {
            this.run();
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectTerminalPartner: function () {
            this.terminals = [];
            setTimeout(() => {
                this.run();
            });
            getTerminals(this.terminalPartnerId, true, (terminals) => {
                this.terminals = terminals;
            });
        },
        selectTerminal: function () {
            setTimeout(() => {
                this.run();
            });
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.perDay = true;
            if (run && this.data === null) {
                this.run();
            }//
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                procedureType: this.procedureType
            };
            if (this.terminalId !== null) {
                params.terminalId = this.terminalId;
            } else if (this.terminalPartnerId !== null) {
                params.terminalPartnerId = this.terminalPartnerId;
            }
            if (this.perDay === true) {
                params.perDay = 1;
            } else if (this.perWeek === true) {
                params.perWeek = 1;
            } else if (this.perMonth === true) {
                params.perMonth = 1;
            } else if (this.perYear === true) {
                params.perYear = 1;
            }
            if (this.origin !== null) {
                params.origin = this.origin;
            }
            dashboard.getEvolutionStats(params).then((data) => {
                this.perDay = data.perDay === true;
                this.perWeek = data.perWeek === true;
                this.perMonth = data.perMonth === true;
                this.perYear = data.perYear === true;
                this.mode = data.mode;
                this.data = data.values;
                this.draw();
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        },
        draw: function () {
            let labels = [], values = [];
            if (this.data === null) {
                return;
            }
            for (let i = 0; i < this.data.length; i++) {
                let row = this.data[i], label = '';
                values.push(row.count);
                if (this.perDay) {
                    label = row.day + ' ' + getShortMonth(row.month);
                } else if (this.perWeek) {
                    label = row.week;
                } else if (this.perMonth) {
                    label = getShortMonth(row.month) + ' ' + (row.year + '').substr(-2, 2);
                } else if (this.perYear) {
                    label = row.year + '';
                }
                labels.push(label);
            }
            let chartConfig = {
                type: 'bar',
                backgroundColor: '#fefefe',
                plotarea: {
                    margin: '30px 30px 50px 30px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: labels,
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 5,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: values,
                        backgroundColor: COLORS.dc.bgColor
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };

    $scope.daEvolutionsStats = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        terminalId: null,
        terminalPartnerId: null,
        chart: 'chart_da_evo',
        perDay: false,
        perWeek: false,
        perMonth: false,
        perYear: false,
        data: null,
        mode: 'day',
        terminals: null,
        origin: 'all',
        procedureType: 'declaration-achat',
        selectMode: function () {
            this.perDay = this.perWeek = this.perMonth = this.perYear = false;
            switch (this.mode) {
                case 'day':
                    this.perDay = true;
                    break;
                case 'week':
                    this.perWeek = true;
                    break;
                case 'month':
                    this.perMonth = true;
                    break;
                case 'year':
                    this.perYear = true;
                    break;
                default:
                    this.perDay = true;
            }
            this.run();
        },
        selectOrigin: function () {
            this.run();
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectTerminalPartner: function () {
            this.terminals = [];
            setTimeout(() => {
                this.run();
            });
            getTerminals(this.terminalPartnerId, true, (terminals) => {
                this.terminals = terminals;
            });
        },
        selectTerminal: function () {
            setTimeout(() => {
                this.run();
            });
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.perDay = true;
            if (run && this.data === null) {
                this.run();
            }//
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                procedureType: this.procedureType
            };
            if (this.terminalId !== null) {
                params.terminalId = this.terminalId;
            } else if (this.terminalPartnerId !== null) {
                params.terminalPartnerId = this.terminalPartnerId;
            }
            if (this.perDay === true) {
                params.perDay = 1;
            } else if (this.perWeek === true) {
                params.perWeek = 1;
            } else if (this.perMonth === true) {
                params.perMonth = 1;
            } else if (this.perYear === true) {
                params.perYear = 1;
            }
            if (this.origin !== null) {
                params.origin = this.origin;
            }
            dashboard.getEvolutionStats(params).then((data) => {
                this.perDay = data.perDay === true;
                this.perWeek = data.perWeek === true;
                this.perMonth = data.perMonth === true;
                this.perYear = data.perYear === true;
                this.mode = data.mode;
                this.data = data.values;
                this.draw();
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        },
        draw: function () {
            let labels = [], values = [];
            if (this.data === null) {
                return;
            }
            for (let i = 0; i < this.data.length; i++) {
                let row = this.data[i], label = '';
                values.push(row.count);
                if (this.perDay) {
                    label = row.day + ' ' + getShortMonth(row.month);
                } else if (this.perWeek) {
                    label = row.week;
                } else if (this.perMonth) {
                    label = getShortMonth(row.month) + ' ' + (row.year + '').substr(-2, 2);
                } else if (this.perYear) {
                    label = row.year + '';
                }
                labels.push(label);
            }
            let chartConfig = {
                type: 'bar',
                backgroundColor: '#fefefe',
                plotarea: {
                    margin: '30px 30px 50px 30px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: labels,
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 5,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: values,
                        backgroundColor: COLORS.da.bgColor
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };

    $scope.dupEvolutionsStats = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        terminalId: null,
        terminalPartnerId: null,
        chart: 'chart_dup_evo',
        perDay: false,
        perWeek: false,
        perMonth: false,
        perYear: false,
        data: null,
        mode: 'day',
        terminals: null,
        origin: 'all',
        procedureType: 'duplicata',
        selectMode: function () {
            this.perDay = this.perWeek = this.perMonth = this.perYear = false;
            switch (this.mode) {
                case 'day':
                    this.perDay = true;
                    break;
                case 'week':
                    this.perWeek = true;
                    break;
                case 'month':
                    this.perMonth = true;
                    break;
                case 'year':
                    this.perYear = true;
                    break;
                default:
                    this.perDay = true;
            }
            this.run();
        },
        selectOrigin: function () {
            this.run();
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectTerminalPartner: function () {
            this.terminals = [];
            setTimeout(() => {
                this.run();
            });
            getTerminals(this.terminalPartnerId, true, (terminals) => {
                this.terminals = terminals;
            });
        },
        selectTerminal: function () {
            setTimeout(() => {
                this.run();
            });
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.perDay = true;
            if (run && this.data === null) {
                this.run();
            }//
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                procedureType: this.procedureType
            };
            if (this.terminalId !== null) {
                params.terminalId = this.terminalId;
            } else if (this.terminalPartnerId !== null) {
                params.terminalPartnerId = this.terminalPartnerId;
            }
            if (this.perDay === true) {
                params.perDay = 1;
            } else if (this.perWeek === true) {
                params.perWeek = 1;
            } else if (this.perMonth === true) {
                params.perMonth = 1;
            } else if (this.perYear === true) {
                params.perYear = 1;
            }
            if (this.origin !== null) {
                params.origin = this.origin;
            }
            dashboard.getEvolutionStats(params).then((data) => {
                this.perDay = data.perDay === true;
                this.perWeek = data.perWeek === true;
                this.perMonth = data.perMonth === true;
                this.perYear = data.perYear === true;
                this.mode = data.mode;
                this.data = data.values;
                this.draw();
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        },
        draw: function () {
            let labels = [], values = [];
            if (this.data === null) {
                return;
            }
            for (let i = 0; i < this.data.length; i++) {
                let row = this.data[i], label = '';
                values.push(row.count);
                if (this.perDay) {
                    label = row.day + ' ' + getShortMonth(row.month);
                } else if (this.perWeek) {
                    label = row.week;
                } else if (this.perMonth) {
                    label = getShortMonth(row.month) + ' ' + (row.year + '').substr(-2, 2);
                } else if (this.perYear) {
                    label = row.year + '';
                }
                labels.push(label);
            }
            let chartConfig = {
                type: 'bar',
                backgroundColor: '#fefefe',
                plotarea: {
                    margin: '30px 30px 50px 30px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: labels,
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 5,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: values,
                        backgroundColor: COLORS.dup.bgColor
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };

    $scope.velEvolutionsStats = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        terminalId: null,
        terminalPartnerId: null,
        chart: 'chart_vel_evo',
        perDay: false,
        perWeek: false,
        perMonth: false,
        perYear: false,
        data: null,
        mode: 'day',
        terminals: null,
        origin: 'all',
        procedureType: 'vente-en-ligne',
        selectMode: function () {
            this.perDay = this.perWeek = this.perMonth = this.perYear = false;
            switch (this.mode) {
                case 'day':
                    this.perDay = true;
                    break;
                case 'week':
                    this.perWeek = true;
                    break;
                case 'month':
                    this.perMonth = true;
                    break;
                case 'year':
                    this.perYear = true;
                    break;
                default:
                    this.perDay = true;
            }
            this.run();
        },
        selectOrigin: function () {
            this.run();
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectTerminalPartner: function () {
            this.terminals = [];
            setTimeout(() => {
                this.run();
            });
            getTerminals(this.terminalPartnerId, true, (terminals) => {
                this.terminals = terminals;
            });
        },
        selectTerminal: function () {
            setTimeout(() => {
                this.run();
            });
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.perDay = true;
            if (run && this.data === null) {
                this.run();
            }//
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD'),
                procedureType: this.procedureType
            };
            if (this.terminalId !== null) {
                params.terminalId = this.terminalId;
            } else if (this.terminalPartnerId !== null) {
                params.terminalPartnerId = this.terminalPartnerId;
            }
            if (this.perDay === true) {
                params.perDay = 1;
            } else if (this.perWeek === true) {
                params.perWeek = 1;
            } else if (this.perMonth === true) {
                params.perMonth = 1;
            } else if (this.perYear === true) {
                params.perYear = 1;
            }
            if (this.origin !== null) {
                params.origin = this.origin;
            }
            dashboard.getEvolutionStats(params).then((data) => {
                this.perDay = data.perDay === true;
                this.perWeek = data.perWeek === true;
                this.perMonth = data.perMonth === true;
                this.perYear = data.perYear === true;
                this.mode = data.mode;
                this.data = data.values;
                this.draw();
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        },
        draw: function () {
            let labels = [], values = [];
            if (this.data === null) {
                return;
            }
            for (let i = 0; i < this.data.length; i++) {
                let row = this.data[i], label = '';
                values.push(row.count);
                if (this.perDay) {
                    label = row.day + ' ' + getShortMonth(row.month);
                } else if (this.perWeek) {
                    label = row.week;
                } else if (this.perMonth) {
                    label = getShortMonth(row.month) + ' ' + (row.year + '').substr(-2, 2);
                } else if (this.perYear) {
                    label = row.year + '';
                }
                labels.push(label);
            }
            let chartConfig = {
                type: 'bar',
                backgroundColor: '#fefefe',
                plotarea: {
                    margin: '30px 30px 50px 30px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: labels,
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 5,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: values,
                        backgroundColor: COLORS.vel.bgColor
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };

    $scope.getGlobalEvolutionsStats = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        terminalId: null,
        terminalPartnerId: null,
        chart: 'chart_global_05',
        perDay: false,
        perWeek: false,
        perMonth: false,
        perYear: false,
        data: null,
        mode: 'day',
        terminals: null,
        origin: 'all',
        selectMode: function () {
            this.perDay = this.perWeek = this.perMonth = this.perYear = false;
            switch (this.mode) {
                case 'day':
                    this.perDay = true;
                    break;
                case 'week':
                    this.perWeek = true;
                    break;
                case 'month':
                    this.perMonth = true;
                    break;
                case 'year':
                    this.perYear = true;
                    break;
                default:
                    this.perDay = true;
            }
            this.run();
        },
        selectOrigin: function () {
            this.run();
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectTerminalPartner: function () {
            this.terminals = [];
            setTimeout(() => {
                this.run();
            });
            getTerminals(this.terminalPartnerId, true, (terminals) => {
                this.terminals = terminals;
            });
        },
        selectTerminal: function () {
            setTimeout(() => {
                this.run();
            });
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.perDay = true;
            if (run && this.data === null) {
                this.run();
            }//
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            };
            if (this.terminalId !== null) {
                params.terminalId = this.terminalId;
            } else if (this.terminalPartnerId !== null) {
                params.terminalPartnerId = this.terminalPartnerId;
            }
            if (this.perDay === true) {
                params.perDay = 1;
            } else if (this.perWeek === true) {
                params.perWeek = 1;
            } else if (this.perMonth === true) {
                params.perMonth = 1;
            } else if (this.perYear === true) {
                params.perYear = 1;
            }
            if (this.origin !== null) {
                params.origin = this.origin;
            }
            dashboard.getEvolutionStats(params).then((data) => {
                this.perDay = data.perDay === true;
                this.perWeek = data.perWeek === true;
                this.perMonth = data.perMonth === true;
                this.perYear = data.perYear === true;
                this.mode = data.mode;
                this.data = data.values;
                this.draw();
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        },
        draw: function () {
            let labels = [], values = [];
            if (this.data === null) {
                return;
            }
            for (let i = 0; i < this.data.length; i++) {
                let row = this.data[i], label = '';
                values.push(row.count);
                if (this.perDay) {
                    label = row.day + ' ' + getShortMonth(row.month);
                } else if (this.perWeek) {
                    label = row.week;
                } else if (this.perMonth) {
                    label = getShortMonth(row.month) + ' ' + (row.year + '').substr(-2, 2);
                } else if (this.perYear) {
                    label = row.year + '';
                }
                labels.push(label);
            }
            let chartConfig = {
                type: 'bar',
                backgroundColor: '#fefefe',
                plotarea: {
                    margin: '30px 30px 50px 30px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: labels,
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 5,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: values,
                        backgroundColor: '#118400'
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            }, 500);
        }
    };

    $scope.usersStats = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        userId: null,
        actionType: null,
        chart: 'chart_users',
        perDay: false,
        perWeek: false,
        perMonth: false,
        perYear: false,
        data: null,
        mode: 'day',
        users: null,
        actionTypes: [
            {
                id: 'handle-procedure',
                name: 'Prendre en charge la démarche'
            },
            {
                id: 'send-procedure-to-tms',
                name: 'Transmettre la démarche à TMS'
            }
        ],
        selectActionType: function () {
            setTimeout(() => {
                this.run();
            });
        },
        selectMode: function () {
            this.perDay = this.perWeek = this.perMonth = this.perYear = false;
            switch (this.mode) {
                case 'day':
                    this.perDay = true;
                    break;
                case 'week':
                    this.perWeek = true;
                    break;
                case 'month':
                    this.perMonth = true;
                    break;
                case 'year':
                    this.perYear = true;
                    break;
                default:
                    this.perDay = true;
            }
            this.run();
        },
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectUser: function () {
            setTimeout(() => {
                this.run();
            });
        },
        init: function (run = false) {
            let dateFrom = new Date();
            dateFrom.setDate(1);
            this.dateFrom = dateFrom;
            this.dateTo = new Date();
            this.perDay = true;
            this.actionType = 'send-procedure-to-tms';
            this.getUsers();
            if (run && this.data === null) {
                this.run();
            }//
        },
        getUsers: function () {
            dashboard.getUsersStatuses().then((users) => {
                users.sort(function (a, b) {
                    return (a.connected === true && b.connected === true) ? 0 : (a.connected === true ? -1 : 1);
                });
                this.users = users;
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            };
            if (this.userId !== null) {
                params.userId = this.userId;
                if (this.userId === 'all') {
                    delete params.userId;
                }
            }
            if (this.actionType !== null) {
                params.actionType = this.actionType;
            }
            if (this.perDay === true) {
                params.perDay = 1;
            } else if (this.perWeek === true) {
                params.perWeek = 1;
            } else if (this.perMonth === true) {
                params.perMonth = 1;
            } else if (this.perYear === true) {
                params.perYear = 1;
            }
            dashboard.getUsersStats(params).then((data) => {
                this.perDay = data.perDay === true;
                this.perWeek = data.perWeek === true;
                this.perMonth = data.perMonth === true;
                this.perYear = data.perYear === true;
                this.mode = data.mode;
                this.data = data.values;
                this.draw();
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        },
        draw: function () {
            let labels = [], values = [];
            if (this.data === null) {
                return;
            }
            for (let i = 0; i < this.data.length; i++) {
                let row = this.data[i], label = '';
                values.push(row.count);
                if (this.perDay) {
                    label = row.day + ' ' + getShortMonth(row.month);
                } else if (this.perWeek) {
                    label = row.week;
                } else if (this.perMonth) {
                    label = getShortMonth(row.month) + ' ' + (row.year + '').substr(-2, 2);
                } else if (this.perYear) {
                    label = row.year + '';
                }
                labels.push(label);
            }
            let chartConfig = {
                type: 'bar',
                backgroundColor: '#fefefe',
                plotarea: {
                    margin: '30px 30px 50px 30px'
                },
                plot: {
                    stacked: true,
                    tooltip: {
                        padding: '5px 10px',
                        backgroundColor: '#fff',
                        borderRadius: '3px',
                        fontColor: '#333',
                        fontFamily: 'Arial',
                        fontSize: '11px'
                    }
                },
                scaleX: {
                    values: labels,
                    guide: {
                        visible: false
                    },
                    item: {
                        fontColor: '#000',
                        fontSize: '16px',
                        fontWeight: '700',
                        fontFamily: 'heebo'
                    }
                },
                scaleY: {
                    guide: {
                        lineStyle: 'solid'
                    },
                    item: {
                        fontColor: '#999'
                    },
                    lineColor: 'none',
                    lineWidth: '0px',
                    step: 5,
                    tick: {
                        visible: false
                    }
                },
                series: [
                    {
                        values: values,
                        backgroundColor: this.actionType === this.actionTypes[0].id ? COLORS.vel.bgColor : COLORS.da.bgColor,
                        borderRadiusTopLeft: 10,
                        borderRadiusTopRight: 10
                    }
                ]
            };
            setTimeout(() => {
                zingchart.render({
                    id: this.chart,
                    data: chartConfig,
                    height: '100%',
                    width: '100%'
                });
            });
        }
    };

    $scope.usersConnections = {
        minDate: minDate,
        maxDate: maxDate,
        dateFrom: null,
        userId: null,
        data: null,
        selectUser: function () {
            setTimeout(() => {
                this.run();
            });
        },
        init: function () {
            this.dateFrom = new Date();
            this.dateTo = new Date();
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateFrom).format('YYYY-MM-DD')
            };
            if (this.userId === null) {
                return toast.show('Il faut sélectionner un agent.', 'error');
            }
            users.getConnections(this.userId, params).then((data) => {
                this.data = data;
                $scope.$apply();
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        }
    };

    $scope.turnover = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        selectedMonth: null,
        selectedPartner: null,
        partners: [],
        tabletPartner: null,
        terminalPartner: null,
        webAppPartner: null,
        partnerName: null,
        forceNewFileGeneration: false,
        selectMonth() {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        selectPartner() {
            this.tabletPartner = this.terminalPartner = null;
            let partner = this.partners.find(partner => partner._id === this.selectedPartner);
            if (partner) {
                this.partnerName = window._cleanStr(partner.company, 'lower', true);
                this.tabletPartner = partner.TabletPartner;
                this.terminalPartner = partner.TerminalPartner;
                this.webAppPartner = partner.WebAppPartner;
            }
        },
        init() {
            this.selectedMonth = moment().startOf('month');
            this.dateFrom = moment().startOf('month');
            this.dateTo = moment().endOf('month');
            getPartners((data) => {
                this.partners = data;
            });
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            };
            if (this.tabletPartner !== null) {
                params.tabletPartnerId = this.tabletPartner._id;
            }
            if (this.terminalPartner !== null) {
                params.terminalPartnerId = this.terminalPartner._id;
            }
            if (this.webAppPartner !== null) {
                params.webAppPartnerId = this.webAppPartner._id;
            }
            if (this.partnerName !== null) {
                params.partnerName = this.partnerName;
            }
            if (this.forceNewFileGeneration !== false) {
                params.force = 1;
            }
            if (Object.keys(params).length === 2) {
                return alert('Il faut sélectionner un compte partenaire.');
            }
            dashboard.getTurnover(params).then((result) => {
                if (result && result.file) {
                    downloadFile(result.file);
                }
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        }
    };

    $scope.countsBySourcePerMonth = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        init: function () {
            this.dateFrom = moment().startOf('month');
            this.dateTo = moment().endOf('month');
        },
        run: function () {
            let params = {
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            };
            dashboard.getCountsBySourcePerMonthExcel(params).then((result) => {
                if (result && result.file) {
                    downloadFile(result.file);
                }
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        }
    };

    $scope.clientsContactCompletedProcedures = {
        minDate: minDate,
        maxDate: maxDate,
        target: null,
        dateTo: null,
        dateFrom: null,
        selectedMonth: null,
        terminals: null,
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        init: function () {
            if (this.terminals === null) {
                let _this = this;
                getTerminals(null, true, function (terminals) {
                    _this.terminals = terminals;
                });
            }
            this.selectedMonth = moment().startOf('month');
            this.dateFrom = moment().startOf('month');
            this.dateTo = moment().endOf('month');
        },
        run: function () {
            dashboard.getClientsContactCompletedProcedures({
                target: this.target,
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            }).then((result) => {
                if (result && result.file) {
                    downloadFile(result.file);
                }
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        }
    };

    $scope.getCompletedCIMProceduresTaxes = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        selectedMonth: null,
        orderBy: 'date',
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        init: function () {
            this.selectedMonth = moment().startOf('month');
            this.dateFrom = moment();
            this.dateTo = moment();
        },
        run: function () {
            dashboard.getCompletedCIMProceduresTaxes({
                orderBy: this.orderBy,
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            }).then((result) => {
                if (result && result.file) {
                    downloadFile(result.file);
                }
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        }
    };

    $scope.getPayments = {
        minDate: minDate,
        maxDate: maxDate,
        dateTo: null,
        dateFrom: null,
        selectedMonth: null,
        selectMonth: function () {
            this.dateFrom = moment(this.selectedMonth).startOf('month').toDate();
            this.dateTo = moment(this.selectedMonth).endOf('month').toDate();
            this.run();
        },
        init: function () {
            this.selectedMonth = moment().startOf('month');
            this.dateFrom = moment();
            this.dateTo = moment();
        },
        run: function () {
            dashboard.getPayments({
                orderBy: this.orderBy,
                dateFrom: moment(this.dateFrom).format('YYYY-MM-DD'),
                dateTo: moment(this.dateTo).format('YYYY-MM-DD')
            }).then((result) => {
                if (result && result.file) {
                    downloadFile(result.file);
                }
            }).catch((err) => {
                toast.show(err ? err : defaultErrorMessage, 'error');
            });
        }
    };

//------------------------------------------------------------------------------

    function downloadFile(fileURL) {
        fetch(fileURL, {
            headers: {
                'Authorization': 'Bearer ' + $cookies.get(config.auth.token),
                'x-from': 'bo'
            }
        }).then(response => response.blob()).then(blob => {
            let url = window.URL.createObjectURL(blob);
            let link = document.createElement('a');
            link.href = url;
            link.download = new URL(fileURL).pathname.split('/').pop();
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    function getTerminals(terminalPartnerId = null, simple = false, cb = null) {
        let storageName = 'terminals_' + (simple ? 'simple' : '') +
                (terminalPartnerId !== null ? '_' + terminalPartnerId : '');
        let params = {
            enabled: 1
        };
        if (terminalPartnerId !== null) {
            params.TerminalPartnerId = terminalPartnerId;
        }
        let data = JSON.parse(localStorage.getItem(storageName));
        if (!data) {
            terminals[simple === true ? 'getSimple' : 'get'](params).then((data) => {
                localStorage.setItem(storageName, JSON.stringify(data));
                if (cb !== null) {
                    cb(data.items ? data.items : data);
                } else {
                    $scope.terminals = data.items;
                    $scope.$apply();
                }
            }).catch((err) => {
                if (cb !== null) {
                    cb(null, err);
                }
                $scope.error = err;
            });
        } else {
            if (cb !== null) {
                cb(data.items ? data.items : data);
            } else {
                $scope.terminals = data.items;
            }
        }//
    }

    function getPartners(cb = null) {
        let storageName = 'clients_partners_simple';
        let params = {
            simple: 1
        };
        let data = JSON.parse(localStorage.getItem(storageName));
        if (!data) {
            clients.getPartners(params).then((data) => {
                localStorage.setItem(storageName, JSON.stringify(data));
                if (cb !== null) {
                    cb(data);
                }
            }).catch((err) => {
                if (cb !== null) {
                    cb(null, err);
                }
            });
        } else {
            if (cb !== null) {
                cb(data);
            }
        }//
    }

    function getTerminalPartners(cb = null) {
        let params = {
            partners: 1,
            active: 1
        };
        let data = JSON.parse(localStorage.getItem('terminalPartners'));
        if (!data) {
            terminals.get(params, 1, 100).then((data) => {
                localStorage.setItem('terminalPartners', JSON.stringify(data));
                if (cb !== null) {
                    cb(data.items);
                } else {
                    $scope.terminalPartners = data.items;
                    setTimeout(() => {
                        $scope.$apply();
                    });
                }
            }).catch((err) => {
                $scope.error = err;
            });
        } else {
            if (cb !== null) {
                cb(data.items);
            } else {
                $scope.terminalPartners = data.items;
                setTimeout(() => {
                    $scope.$apply();
                });
            }
        } //
    }

    function getDatesAndValues(data, type = null) {
        if (type !== null) {
            data = data[type];
        }
        let from = 0, to = data.length;
        let res = [];
        for (let i = from; i < to; i++) {
            let d = data[i].day;
            d = (d < 10 ? '0' : '') + d;
            let m = data[i].month;
            m = (m < 10 ? '0' : '') + m;
            res.push([d + '-' + m + '-' + data[i].year, data[i].total]);
        }
        return res;
    }
    function getTotals(data, type = null) {
        if (type !== null) {
            data = data[type];
        }
        let from = 0, to = data.length;
        let res = [];
        for (let i = from; i < to; i++) {
            res.push(data[i].total);
        }
        return res;
    }
    function getCounts(data, type = null) {
        if (type !== null) {
            data = data[type];
        }
        let from = 0, to = data.length;
        let res = [];
        for (let i = from; i < to; i++) {
            res.push(data[i].nb);
        }
        return res;
    }
    function getDates(data, type = null) {
        if (type !== null) {
            data = data[type];
        }
        let from = 0, to = data.length;
        let res = [];
        for (let i = from; i < to; i++) {
            let d = data[i].day;
            d = (d < 10 ? '0' : '') + d;
            let m = data[i].month;
            m = (m < 10 ? '0' : '') + m;
            res.push(d + '-' + m + '-' + data[i].year);
        }
        return res;
    }

//----------------------------------------------------------------------- init -

    $scope.$on('$viewContentLoaded', function () {
        console.log('OK, content was loaded');

        $scope.getPayments.init();
        $scope.getCompletedCIMProceduresTaxes.init();

        $scope.turnover.init();
        $scope.countsBySourcePerMonth.init();

        $scope.clientsContactCompletedProcedures.init();

        $scope.getGlobalEvolutionsStats.init(true);

        $scope.paidProceduresCountsPerPeriodForTerminalPartners.init(true);

        //$scope.laPosteWeeklyData.init();

        $scope.getEvolutionsStats.init();

        getTerminalPartners();

        $scope.refunds.run();
        $scope.taxes.init();

        if ($scope.hasDashboardPermission) {
            $scope.paidProceduresCounts.init(true);
        }

        if ($scope.hasAgentsPermission) {
            $scope.usersStats.init();
            $scope.usersConnections.init();
        }
    });

});

